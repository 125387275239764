class CountryFieldHandler {
  constructor() {
    this.countryField = $("[data-behavior='country-field']")
    this.stateField = $("[data-behavior='state']")
    this.regionField = $("[data-behavior='region']")
    this.bindEvents()
    this.init()
  }

  bindEvents() {
    this.countryField.on("change", () => this.toggleFields())
  }

  init() {
    this.toggleFields()
  }

  toggleFields() {
    const country = this.countryField.val()
    if (country === "US") {
      this.stateField.show()
      this.regionField.hide()
    } else {
      this.stateField.hide()
      this.regionField.show()
    }
  }
}

$(document).on("turbolinks:load", function () {
  if ($("#education-degrees").length === 0) return
  console.log("registration/education_certifications.js got past the guard!")

  // $('#education-degrees').on('cocoon:after-insert', function () {
  //   $('.ui.dropdown').dropdown({ placeholder: "Select degree type..." })
  // })

  if ($(".nested-fields").length > 0) {
    new CountryFieldHandler()
  }

  // Initialize for newly added nested fields
  $(document).on("cocoon:after-insert", function () {
    new CountryFieldHandler()
  })

  function generateYearSelectWithOptions(selectId, selectName) {
    let $select = $("<select>")
      .addClass("date-select")
      .attr("id", selectId)
      .attr("name", selectName)
    for (let i = 1930; i <= 2020; i++) {
      $select.append(new Option(i, i))
    }
    return $select
  }

  function generateMonthSelectWithOptions(selectId, selectName) {
    let $select = $("<select>")
      .addClass("date-select")
      .attr("id", selectId)
      .attr("name", selectName)
    let months = [
      ["Jan", 1],
      ["Feb", 2],
      ["Mar", 3],
      ["Apr", 4],
      ["May", 5],
      ["Jun", 6],
      ["Jul", 7],
      ["Aug", 8],
      ["Sep", 9],
      ["Oct", 10],
      ["Nov", 11],
      ["Dec", 12],
    ]
    for (const month of months) {
      $select.append(new Option(month[0], month[1]))
    }
    return $select
  }

  function generateDateSelectWithOptions(selectId, selectName) {
    let $select = $("<select>")
      .addClass("date-select")
      .attr("id", selectId)
      .attr("name", selectName)
    for (let i = 1; i <= 31; i++) {
      $select.append(new Option(i, i))
    }
    return $select
  }

  function generateUsStateSelect(selectId, selectName, multiple = false) {
    let $select = $("<select>")
      .attr("id", selectId)
      .attr("name", selectName)
      .addClass("ui fluid search dropdown")
    if (multiple) {
      $select.attr("multiple", true)
    }
    let states = [
      ["", ""],
      ["Alabama", "AL"],
      ["Alaska", "AK"],
      ["Arizona", "AZ"],
      ["Arkansas", "AR"],
      ["California", "CA"],
      ["Colorado", "CO"],
      ["Connecticut", "CT"],
      ["Delaware", "DE"],
      ["District of Columbia", "DC"],
      ["Florida", "FL"],
      ["Georgia", "GA"],
      ["Hawaii", "HI"],
      ["Idaho", "ID"],
      ["Illinois", "IL"],
      ["Indiana", "IN"],
      ["Iowa", "IA"],
      ["Kansas", "KS"],
      ["Kentucky", "KY"],
      ["Louisiana", "LA"],
      ["Maine", "ME"],
      ["Maryland", "MD"],
      ["Massachusetts", "MA"],
      ["Michigan", "MI"],
      ["Minnesota", "MN"],
      ["Mississippi", "MS"],
      ["Missouri", "MO"],
      ["Montana", "MT"],
      ["Nebraska", "NE"],
      ["Nevada", "NV"],
      ["New Hampshire", "NH"],
      ["New Jersey", "NJ"],
      ["New Mexico", "NM"],
      ["New York", "NY"],
      ["North Carolina", "NC"],
      ["North Dakota", "ND"],
      ["Ohio", "OH"],
      ["Oklahoma", "OK"],
      ["Oregon", "OR"],
      ["Pennsylvania", "PA"],
      ["Puerto Rico", "PR"],
      ["Rhode Island", "RI"],
      ["South Carolina", "SC"],
      ["South Dakota", "SD"],
      ["Tennessee", "TN"],
      ["Texas", "TX"],
      ["Utah", "UT"],
      ["Vermont", "VT"],
      ["Virginia", "VA"],
      ["Washington", "WA"],
      ["West Virginia", "WV"],
      ["Wisconsin", "WI"],
      ["Wyoming", "WY"],
    ]
    for (const state of states) {
      $select.append(new Option(state[0], state[1]))
    }
    return $select
  }

  let yesProfDesContainer = $("#yes-professional-designations-container")
  let noProfDesContainer = $("#no-professional-designations-container")
  let profDesSelections = $("#professional-designations-selections")
  let radioProfessionalDesignations = $(
    ":radio[name='professional_designations']"
  )

  radioProfessionalDesignations.on("click", function (event) {
    if (event.target.value === "true") {
      yesProfDesContainer.addClass("checked")
      noProfDesContainer.removeClass("checked")
      profDesSelections.addClass("showing")
      profDesSelections.delay(1000).addClass("overflow-visible")
    } else if (event.target.value === "false") {
      noProfDesContainer.addClass("checked")
      yesProfDesContainer.removeClass("checked")
      profDesSelections.removeClass("showing")
      profDesSelections.delay(1000).removeClass("overflow-visible")
    }
  })

  function rebindDegreeTypeSelect() {
    $(".nested-fields").each(function () {
      toggleDateConferredVisible($(this))
    })

    $("[data-behavior='degree-type-select']").off("change").on("change", function () {
      toggleDateConferredVisible($(this).closest(".nested-fields"))
    })
  }

  function toggleDateConferredVisible(container) {
    const degreeType = container.find("[data-behavior='degree-type-select']").val()
    if (degreeType === "LL.M" || degreeType === "JD") {
      container.find("#date-conferred-container").show()
    } else {
      container.find("#date-conferred-container").hide()
    }
  }

  rebindDegreeTypeSelect()

  $(":checkbox.professional-designation-selection").change(function () {
    const timeId = new Date().getTime()
    const designationType = $(this).attr("id")
    const parameterizedDesignationType = this.dataset
      .designationTypeParameterized
    const selectionDiv = $(this).parent().closest("div")
    const hiddenIdInput = $(`input#${parameterizedDesignationType}-id`)

    if (this.checked) {
      $(`input#${parameterizedDesignationType}-destroy`).remove()

      if (hiddenIdInput.length === 0) {
        selectionDiv.append(
          `<input type='hidden' id='new-${parameterizedDesignationType}' name='profile[professional_designations_attributes][${timeId}][designation_type]' value='${designationType}' />`
        )
      }
    } else {
      selectionDiv
        .children(`input#new-${parameterizedDesignationType}`)
        .remove()

      if (hiddenIdInput.length) {
        const idVal = hiddenIdInput.val()
        selectionDiv.append(
          `<input type='hidden' id='${parameterizedDesignationType}-destroy' name='profile[professional_designations_attributes][${idVal}][_destroy]' value='1' />`
        )
      }
    }
    console.log(designationType)
    if (designationType === "Other") {
      manageOtherDesignationField(timeId, this)
      manageDesignationFields(timeId, this)
    } else {
      manageDesignationFields(timeId, this)
    }
  })

  function manageOtherDesignationField(timeId, checkbox) {
    const otherDiv = $(" div#other-selection ")
    if (checkbox.checked) {
      if (otherDiv.children("#other-id").length === 0) {
        let inputDiv = $("<div>").addClass("field")
        otherDiv.append(inputDiv)
        inputDiv.append(
          `<input type='text' name='profile[professional_designations_attributes][${timeId}][other_designation_type]' id='other-designation-specification' placeholder='Please specify' />`
        )
      }
      otherDiv.find("#other-designation-specification").show()
    } else {
      if (otherDiv.children("#other-id").length) {
        otherDiv.find("#other-designation-specification").hide()
      } else {
        otherDiv.find("#other-designation-specification").remove()
      }
    }
  }

  function manageDesignationFields(timeId, checkbox) {
    const designationTypeParameterized =
      checkbox.dataset.designationTypeParameterized
    const designationDiv = $(
      `.professional-designation-selection#${designationTypeParameterized}-selection`
    )

    if (checkbox.checked) {
      if (
        designationDiv.find(`input#${designationTypeParameterized}-id`)
          .length === 0
      ) {
        designationDiv.append(
          generateUsStateSelect(
            `${designationTypeParameterized}-designation-states`,
            `profile[professional_designations_attributes][${timeId}][designation_states][]`,
            true
          )
        )
        designationDiv
          .children(".ui.dropdown")
          .dropdown({ placeholder: "Select state(s)..." })
      } else {
        designationDiv.find(`.ui.dropdown`).show()
      }
    } else {
      if (
        designationDiv.find(`input#${designationTypeParameterized}-id`).length
      ) {
        console.log("should be hiding here")
        designationDiv.find(`.ui.dropdown`).hide()
      } else {
        designationDiv.find(`.ui.dropdown`).remove()
      }
    }
  }

  $("input.professional-license").change(function () {
    const timeId = new Date().getTime()
    const licenseTypeParameterized = this.dataset.licenseTypeParameterized
    const parentDiv = $(this).parent().closest("div")

    if (this.checked) {
      if (parentDiv.find(`#${licenseTypeParameterized}-id`).length === 0) {
        parentDiv.append(
          `<input type='hidden' name='profile[professional_licenses_attributes][${timeId}][license_type]' value='${this.value}' id='license-type-${licenseTypeParameterized}' />`
        )
        // disclude "series" licenses from having states as they are national
        if (!licenseTypeParameterized.includes("series")) {
          parentDiv.append(
            generateUsStateSelect(
              `${licenseTypeParameterized}-state-select`,
              `profile[professional_licenses_attributes][${timeId}][license_states][]`,
              true
            )
          )
          $(`#${licenseTypeParameterized}-state-select`).dropdown({
            placeholder: "Select state(s)",
          })
        }
      } else {
        parentDiv
          .find(`input[type='hidden']#${licenseTypeParameterized}-destroy`)
          .remove()
        parentDiv.children(".ui.dropdown").show()
      }
    } else {
      if (parentDiv.find(`#${licenseTypeParameterized}-id`).length) {
        const licenseId = parentDiv
          .find(`#${licenseTypeParameterized}-id`)
          .val()
        parentDiv.append(
          `<input type='hidden' value='1' name='profile[professional_licenses_attributes][${licenseId}][_destroy]' id='${licenseTypeParameterized}-destroy' />`
        )
        parentDiv.children(".ui.dropdown").hide()
      } else {
        parentDiv.find("input[type='hidden']").remove()
        parentDiv.children(`.ui.dropdown`).remove()
      }
    }
  })

  let yesRegisteredAdvisorContainer = $(
    "#yes-registered-investment-advisor-container"
  )
  let noRegisteredAdvisorContainer = $(
    "#no-registered-investment-advisor-container"
  )
  let typeOfRegisteredAdvisor = $(
    "#type-of-registered-investment-advisor-selection"
  )
  let radioRegisteredAdvisor = $("input[name='registered_investment_advisor']")

  radioRegisteredAdvisor.on("click", function (event) {
    if (event.target.value === "true") {
      yesRegisteredAdvisorContainer.addClass("checked")
      noRegisteredAdvisorContainer.removeClass("checked")
      typeOfRegisteredAdvisor.addClass("showing")
      typeOfRegisteredAdvisor.delay(500).addClass("overflow-visible")
    } else if (event.target.value === "false") {
      noRegisteredAdvisorContainer.addClass("checked")
      yesRegisteredAdvisorContainer.removeClass("checked")
      typeOfRegisteredAdvisor.removeClass("showing")
      typeOfRegisteredAdvisor.delay(500).removeClass("overflow-visible")
    }
  })

  $("input[name='type_of_advisor']").change(function () {
    if (this.value === "SEC") {
      $("#state-registered-advisor-states-selection").hide()
    } else if (this.value === "State") {
      $("#state-registered-advisor-states-selection")
        .removeAttr("hidden")
        .show()
    }
  })

  let yesAffiliatedBrokerContainer = $("#yes-broker-affiliation-container")
  let noAffiliatedBrokerContainer = $("#no-broker-affiliation-container")
  let radioAffiliatedBroker = $(":radio[name='broker_dealer_affiliation']")

  radioAffiliatedBroker.on("change", function (event) {
    if (event.target.value === "true") {
      yesAffiliatedBrokerContainer.addClass("checked")
      noAffiliatedBrokerContainer.removeClass("checked")
    } else if (event.target.value === "false") {
      noAffiliatedBrokerContainer.addClass("checked")
      yesAffiliatedBrokerContainer.removeClass("checked")

      $(".affiliation-wrapper input[type='text']").val("")
      $(".affiliation-wrapper input[type='radio']").val("")
      $(".affiliation-wrapper select").val("")
    }
  })

  $(":radio[name='broker_dealer_affiliation']").change(function () {
    if (this.value == "true") {
      $(".affiliation-wrapper").removeAttr("hidden").show()
    } else {
      $(".affiliation-wrapper").hide()
    }
  })

  //Form submit
  $("form#education-certifications").submit(function (event) {
    if ($("input[name='type_of_advisor']:checked").val() === "SEC") {
      $("form#education-certifications").remove(
        "input[name='profile[state_registered_advisor]']"
      )
      $("select#state_registered_advisor_states").val([])

      $("form#education-certifications").append(
        "<input type='hidden' name='profile[sec_advisor]' value=true />"
      )
      $("form#education-certifications").append(
        "<input type='hidden' name='profile[state_registered_advisor]' value=false />"
      )
      $("form#education-certifications").append(
        "<input type='hidden' name='profile[state_registered_advisor_states]' value='' />"
      )
    } else if (
      $("input[name='type_of_advisor']:checked").val() === "State" &&
      $("select#state_registered_advisor_states").val().length > 0
    ) {
      $("form#education-certifications").remove(
        "input[name='profile[sec_advisor]']"
      )

      const stateRegisteredAdvisorStates = $(
        "select#state_registered_advisor_states"
      )
        .val()
        .join()
      $("form#education-certifications").append(
        `<input type='hidden' name='profile[state_registered_advisor_states]' value='${stateRegisteredAdvisorStates}' />`
      )
      $("form#education-certifications").append(
        "<input type='hidden' name='profile[state_registered_advisor]' value=true />"
      )
      $("form#education-certifications").append(
        "<input type='hidden' name='profile[sec_advisor]' value=false />"
      )

      // event.preventDefault()
    }

    if ($("input#broker_dealer_affiliation_false:checked").length === 1) {
      $("input#profile_broker_dealer_affiliation").val(null)
    }
  })

  const affiliations = document.querySelectorAll(".affiliation-radio-button")

  function handleRadioChange(event) {
    // Clear all select elements by rebuilding them
    const selectContainers = document.querySelectorAll(".input-field select")

    selectContainers.forEach((container) => {
      const select = container.querySelector("select")

      if (select) {
        const clonedSelect = select.cloneNode(true)
        container.replaceChild(clonedSelect, select)
      }
    })

    const targetClass = event.target.value.toLowerCase().replace(/\s+/g, "-")
    const currentFields = document.querySelectorAll(".input-field")

    currentFields.forEach((field) => {
      if (field.classList.contains(targetClass)) {
        field.style.display = "block"
      } else {
        field.style.display = "none"
      }
    })
  }

  affiliations.forEach((radioButton) => {
    radioButton.addEventListener("change", handleRadioChange)
    if (radioButton.checked) {
      handleRadioChange({ target: radioButton })
    }
  })

  $(document).on("cocoon:after-insert", function () {
    affiliations.forEach((radioButton) => {
      radioButton.addEventListener("change", handleRadioChange)
    })
    rebindDegreeTypeSelect()
  })
})
